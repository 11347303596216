import React, { createContext, useEffect, useState } from "react";
import $ from "jquery";
import "./App.css";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import New from "./pages/New/New";
import Edit from "./pages/Edit";
import Card from "./pages/Card/Card";

export const AppContext = createContext();

export function AppContextProvider({ children }) {
  const [appData, setAppData] = useState({
    imgSrc: null,
    error: null,
    isFetching: false,
    locale: "ko",
  });

  return (
    <AppContext.Provider value={{ appData, setAppData }}>
      {children}
    </AppContext.Provider>
  );
}

function setScreenSize() {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
}

setScreenSize();

window.addEventListener("resize", () => setScreenSize());

function App() {
  useEffect(() => {
    if (window.$) return;
    (async () => {
      window.$ = window.jQuery = $;
      await import("./components/ZoomableImage/uber-zoom.css");
      await import("./components/ZoomableImage/uber-zoom.js");
    })();
  }, []);
  // console.log(`server: ${process.env.REACT_APP_API_SERVER}`);
  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route path="/" element={<Navigate to="/new" />} />
          <Route path="/new" element={<New />} />
          <Route path="/edit" element={<Edit />} />
          <Route path="/card" element={<Card />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
