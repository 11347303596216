import { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../App";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import "./Card.css";

const Card = () => {
  const PRINT_CODE_TEXT_KO = "출력 인증번호";
  const PRINT_CODE_TEXT_EN = "Print Code";
  const AUTH_CODE_DESC_1_KO = "인증번호는 포토 조회에 꼭 필요합니다.";
  const AUTH_CODE_DESC_2_KO = "캡쳐 후 보관해주세요.";
  const AUTH_CODE_DESC_3_KO = "";
  const AUTH_CODE_DESC_1_EN = "Enter this code into the kiosk.";
  const AUTH_CODE_DESC_2_EN = "Please capture and save it";
  const AUTH_CODE_DESC_3_EN = "for future use.";
  const PRINT_EXPIRES_TEXT_KO = "출력 가능 기간";
  const PRINT_EXPIRES_TEXT_EN = "code expiration date";
  const CONFIRM_TEXT_KO = "확인";
  const CONFIRM_TEXT_EN = "Retry";

  const navigate = useNavigate();
  const { state } = useLocation();
  const { photoCard } = state;
  const { authCode, imageUrls, expiresIn } = photoCard;
  // console.log(expiresIn);
  const expiresInDate = new Date(expiresIn);
  // console.log(expiresInDate);

  const { appData, setAppData } = useContext(AppContext);

  const getText = (text) => {
    //appData.locale = "en";
    switch (text) {
      case "print_code":
        return appData.locale === "ko"
          ? PRINT_CODE_TEXT_KO
          : PRINT_CODE_TEXT_EN;
      case "auth_code_desc1":
        return appData.locale === "ko"
          ? AUTH_CODE_DESC_1_KO
          : AUTH_CODE_DESC_1_EN;
      case "auth_code_desc2":
        return appData.locale === "ko"
          ? AUTH_CODE_DESC_2_KO
          : AUTH_CODE_DESC_2_EN;
      case "auth_code_desc3":
        return appData.locale === "ko"
          ? AUTH_CODE_DESC_3_KO
          : AUTH_CODE_DESC_3_EN;
      case "print_expire":
        return appData.locale === "ko"
          ? PRINT_EXPIRES_TEXT_KO
          : PRINT_EXPIRES_TEXT_EN;
      case "confirm":
        return appData.locale === "ko" ? CONFIRM_TEXT_KO : CONFIRM_TEXT_EN;
    }
  };

  if (photoCard) {
    return (
      <div className="Card">
        <div className="photo-card-image-wrapper">
          <img src={imageUrls[0]} alt="포토 카드 이미지" />
        </div>
        <div className="auth-code-text">
          <span className="auth-code-number-title">
            {getText("print_code")}
          </span>
          <div className="auth-code-number">
            {authCode.split("").map((val, idx) => (
              <span key={idx}>{val}</span>
            ))}
          </div>
          <span className="auth-code-desc1">{getText("auth_code_desc1")}</span>
          <div className="auth-code-desc-wrapper">
            <span className="auth-code-desc2">
              {getText("auth_code_desc2")}
            </span>
            <span className="auth-code-desc3">
              {getText("auth_code_desc3")}
            </span>
          </div>
          <span className="auth-code-expires-in">
            {getText("print_expire")} : {expiresInDate.getFullYear()}.
            {expiresInDate.getMonth() + 1}.{expiresInDate.getDate()}
          </span>
        </div>
        <Button
          className="confirm-button"
          text={getText("confirm")}
          onClick={() => {
            navigate("/");
          }}
        />
      </div>
    );
  } else {
    return <div></div>;
  }
};

export default Card;
